import startsWith from 'lodash/startsWith';

export type CookieSetOptions = {
  path?: string;
  domain?: string;
  secure?: boolean;
  samesite?: string;
  expires?: Date | null;
};

export const defaultCookieOptions: CookieSetOptions = {
  path: '/',
  domain: undefined,
  secure: !startsWith(window.location.host, '192.'),
  samesite: !startsWith(window.location.host, '192.') ? 'Lax' : 'None',
  expires: new Date(new Date().getTime() + 20 * 60 * 60 * 1000), // default 20 hours
};

class Cookie {
  private getCookieValue = (offset: number): string => {
    let endstr = document.cookie.indexOf(';', offset);
    if (endstr === -1) endstr = document.cookie.length;
    return unescape(document.cookie.substring(offset, endstr));
  };

  constructor() {
    this.get = this.get.bind(this);
    this.set = this.set.bind(this);
    this.remove = this.remove.bind(this);
    this.getCookieValue = this.getCookieValue.bind(this);
  }

  get(name: string): string {
    const argument = `${name}=`;
    const argument_len = argument.length;
    const cookie_len = document.cookie.length;
    let i = 0;

    while (i < cookie_len) {
      const j = i + argument_len;
      if (document.cookie.substring(i, j) === argument)
        return this.getCookieValue(j);
      i = document.cookie.indexOf(' ', i) + 1;
      if (i === 0) break;
    }
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  set(name: string, value: string, options: CookieSetOptions = {}): void {
    const { expires, path, domain, secure, samesite } = {
      ...defaultCookieOptions,
      ...options,
    };
    const valuePart = `${name}=${escape(value)}`;
    const expiresPart = expires ? `; expires=${expires.toUTCString()}` : '';
    const pathPart = `; path=${path}`;
    const domainPart = domain ? `; domain=${domain}` : '';
    const securePart = secure ? '; Secure' : '';
    const samesitePart = `; SameSite=${samesite}`;
    document.cookie = `${valuePart}${expiresPart}${pathPart}${domainPart}${securePart}${samesitePart}`;
  }

  remove(name: string): void {
    const exp = new Date();
    exp.setTime(Date.parse('January, 1 1970 01:01:01'));
    this.set(name, '', { ...defaultCookieOptions, expires: exp });
  }
}

export default Cookie;
