export const Spinner = {
  sizes: {
    xl: {
      width: {
        xs: '50px',
        sm: '60px',
        md: '70px',
        lg: '80px',
        xl: '90px',
        '2xl': '100px',
      },
      height: {
        xs: '50px',
        sm: '60px',
        md: '70px',
        lg: '80px',
        xl: '90px',
        '2xl': '100px',
      },
    },
    md: {
      width: {
        xs: '25px',
        sm: '25px',
        md: '30px',
        lg: '40px',
        xl: '50px',
        '2xl': '60px',
      },
      height: {
        xs: '25px',
        sm: '25px',
        md: '30px',
        lg: '40px',
        xl: '50px',
        '2xl': '60px',
      },
    },
  },
  defaultProps: {
    size: 'xl',
  },
};
