import { Action, ActionWithoutPayload } from '@interfaces/provider';
import { IResultResponse } from '@interfaces/result';

enum ResultsActions {
  GET_RESULT_DATA = 'GET_RESULT_DATA',
  SET_RESULT_DATA = 'SET_RESULT_DATA',
  CLEAR_RESULT_DATA = 'CLEAR_RESULT_DATA',
}

export interface IGetResultsDataPayload {
  withBrief?: boolean;
  withSurvey?: boolean;
  withTreatments?: boolean;
}

export type IGetResultsData = Action<
  ResultsActions.GET_RESULT_DATA,
  IGetResultsDataPayload
>;

export type ISetResultsData = Action<
  ResultsActions.SET_RESULT_DATA,
  IResultResponse
>;

export type IClearResultsData =
  ActionWithoutPayload<ResultsActions.CLEAR_RESULT_DATA>;

export type IAction = IGetResultsData | ISetResultsData | IClearResultsData;

export default ResultsActions;
