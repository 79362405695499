import axios from 'axios'

const { REACT_APP_HEADLESS_CMS_API_HOST, REACT_APP_HEADLESS_CMS_API_KEY } = process.env

const instance = axios.create({
  baseURL: `${REACT_APP_HEADLESS_CMS_API_HOST}/api`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${REACT_APP_HEADLESS_CMS_API_KEY}`
  }
})

export default instance
