import ScreeningQuestionsActionTypes, { IAction } from './actionTypes';
import { IScreeningQuestion } from '@interfaces/screeningQuestions';
import { StateBuilder } from '@interfaces/stateBuilder';

export type IScreeningQuestionsState = StateBuilder<IScreeningQuestion[], null>;

export const defaultState: IScreeningQuestionsState = {
  data: [] as IScreeningQuestion[],
  meta: null,
  loading: false,
};

export default (
  state: IScreeningQuestionsState,
  action: IAction,
): IScreeningQuestionsState => {
  switch (action.type) {
    case ScreeningQuestionsActionTypes.GET_SCREENING_QUESTIONS_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ScreeningQuestionsActionTypes.SET_SCREENING_QUESTIONS_DATA: {
      const data = action.payload;
      return {
        ...state,
        data,
        loading: false,
      };
    }
    case ScreeningQuestionsActionTypes.CLEAR_SCREENING_QUESTIONS_DATA: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
