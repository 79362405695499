export const Modal = {
  baseStyle: {
    dialog: {
      maxWidth: {
        xs: '100%',
        sm: '90%',
        md: '63%',
      },
      minWidth: '63%',
      maxHeight: '732px',
      bg: 'white',
      position: 'absolute',
      m: '0 auto',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%) !important',
      textAlign: 'center',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '20px',
      p: {
        xs: '30px 40px 60px 40px',
        md: '60px 60px 90px 60px',
        lg: '76px 76px 120px 76px',
        '2xl': '96px 96px 156px 96px',
      },
    },
    closeButton: {
      position: 'absolute',
      top: '30px',
      right: '20px',
      svg: {
        fontSize: {
          xs: '20px',
          md: '25px',
          lg: '30px',
          '2xl': '40px',
        },
        color: 'descentGrey',
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: {
        xs: 'space-around',
        md: 'space-between',
      },
      button: {
        p: {
          xs: '10px 25px',
          md: '12px 28px',
          lg: '18px 35px',
          '2xl': '22px 40px',
        },
        fontFamily: 'body',
        fontWeight: 400,
        fontSize: {
          xs: '20px',
          md: '22px',
          lg: '25px',
          xl: '40px',
          '2xl': '45px',
        },
        boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
        lineHeight: {
          xs: '22px',
          md: '24px',
          lg: '29px',
          xl: '36px',
          '2xl': '43px',
        },
        borderRadius: '20px',
        color: 'white',
        _hover: {},
        _active: {},
        _disabled: {
          opacity: 0.5,
        },
        svg: {
          ml: '15%',
        },
      },
    },
    header: {
      variant: '',
      fontFamily: 'body',
      fontWeight: 700,
      color: 'brand.500',
      mb: '40px',
      fontSize: {
        xs: '30px',
        sm: '32px',
        md: '36px',
        lg: '44px',
        xl: '52px',
        '2xl': '60px',
      },
      lineHeight: {
        xs: '35px',
        sm: '37px',
        md: '44px',
        lg: '54px',
        xl: '62px',
        '2xl': '72px',
      },
    },
    body: {
      fontFamily: 'body',
      fontWeight: 500,
      color: 'brand.500',
      mb: '80px',
      fontSize: {
        xs: '16px',
        md: '20px',
        lg: '25px',
        xl: '30px',
        '2xl': '35px',
      },
      lineHeight: {
        xs: '22px',
        md: '24px',
        lg: '29px',
        xl: '36px',
        '2xl': '41px',
      },
    },
  },
};
