export const Slider = {
  baseStyle: {
    track: {
      height: {
        md: '12px',
        xl: '15px',
      },
      borderRadius: '40px',
      bgColor: 'white',
      borderStyle: 'solid',
      borderWidth: {
        md: '1.5px',
        xl: '2px',
      },
      borderColor: 'brand.300',
    },
    filledTrack: {
      height: {
        md: '12px',
        xl: '15px',
      },
      ml: '-2px',
      borderRadius: '40px',
      bgColor: 'lightBlue',
    },
    mark: {
      color: 'brand.500',
      mt: {
        md: '20px',
        lg: '25px',
        xl: '30px',
        '2xl': '35px',
      },
      fontWeight: 400,
      fontFamily: 'body',
      fontSize: {
        md: '15px',
        lg: '20px',
        xl: '25px',
        '2xl': '30px',
      },
      lineHeight: {
        md: '20px',
        lg: '27px',
        xl: '34px',
        '2xl': '41px',
      },
    },
    thumb: {
      outline: 'none',
      div: {
        w: {
          md: '35px',
          lg: '50px',
          xl: '65px',
          '2xl': '86px',
        },
        h: {
          md: '35px',
          lg: '50px',
          xl: '65px',
          '2xl': '86px',
        },
        mt: {
          md: '-18px',
          lg: '-25px',
          xl: '-33px',
          '2xl': '-43px',
        },
        ml: {
          md: '-8px',
          lg: '-17px',
          xl: '-23px',
          '2xl': '-32px',
        },
        outline: 'none',
        borderRadius: '50%',
        border: {
          md: '4px solid #CED7F3',
          lg: '6px solid #CED7F3',
          xl: '8px solid #CED7F3',
          '2xl': '10px solid #CED7F3',
        },
        bgColor: 'lightGray',
        fontSize: {
          md: '15px',
          lg: '18px',
          xl: '20px',
          '2xl': '25px',
        },
        fontFamily: 'body',
        lineHeight: {
          md: '27px',
          lg: '38px',
          xl: '49px',
          '2xl': '66px',
        },
        textAlign: 'center',
        color: 'brand.500',
        cursor: 'pointer',
      },
    },
  },
};
