export const navList = [
  {
    name: 'header.nav.features',
    href: '/',
  },
  {
    name: 'header.nav.pricing',
    href: '/',
  },
  {
    name: 'header.nav.community',
    href: '/',
  },
  {
    name: 'header.nav.support',
    href: '/',
  }
]
