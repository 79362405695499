import { Action, ActionWithoutPayload } from '@interfaces/provider';
import { IScreeningQuestion, IScreeningQuestionResponse } from "@interfaces/screeningQuestions";

enum ParticipantActions {
  GET_SCREENING_QUESTIONS_DATA = 'GET_SCREENING_QUESTIONS_DATA',
  SET_SCREENING_QUESTIONS_DATA = 'SET_SCREENING_QUESTIONS_DATA',
  CLEAR_SCREENING_QUESTIONS_DATA = 'CLEAR_SCREENING_QUESTIONS_DATA'
}

export type IGetScreeningQuestionsData = Action<ParticipantActions.GET_SCREENING_QUESTIONS_DATA, IScreeningQuestion>

export type ISetScreeningQuestionsData = Action<ParticipantActions.SET_SCREENING_QUESTIONS_DATA, IScreeningQuestionResponse>

export type IClearScreeningQuestionsData = ActionWithoutPayload<ParticipantActions.CLEAR_SCREENING_QUESTIONS_DATA>;

export type IAction =
  | IGetScreeningQuestionsData
  | ISetScreeningQuestionsData
  | IClearScreeningQuestionsData

export default ParticipantActions;
