import createProvider from '@helpers/createProvider';
import reducer, { IResultsState, defaultState } from './reducer';

export const {
  Provider: ResultsProvider,
  useState: useResultsState,
  useDispatch: useResultsDispatch,
  getDispatch: getResultsDispatch,
  dispatch: resultsDispatchAction,
} = createProvider<IResultsState>(reducer, defaultState);
