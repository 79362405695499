import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

const LoadingPage = () => {
  return (
    <Box textAlign="center" padding="150px 0">
      <Spinner
        thickness="4px"
        speed="0.75s"
        emptyColor="brand.300"
        color="brand.500"
        size="xl"
      />
    </Box>
  );
};

export default LoadingPage;
