export const Text = {
  sizes: {
    xs: {
      fontWeight: 400,
      color: 'brand.500',
      fontFamily: 'body',
      fontSize: {
        xs: '10px',
        sm: '14px',
        md: '12px',
        lg: '15px',
        xl: '18px',
        '2xl': '20px',
      },
      lineHeight: {
        xs: '10px',
        md: '15px',
        lg: '18px',
        xl: '21px',
        '2xl': '23px',
      },
    },
    sm: {
      fontWeight: 400,
      color: 'brand.500',
      fontFamily: 'body',
      fontSize: {
        xs: '10px',
        md: '12px',
        lg: '15px',
        xl: '20px',
        '2xl': '25px',
      },
      lineHeight: {
        xs: '13px',
        md: '17px',
        lg: '21px',
        xl: '28px',
        '2xl': '36px',
      },
    },
    md: {
      fontWeight: 400,
      color: 'brand.500',
      fontFamily: 'body',
      fontSize: {
        xs: '18px',
        md: '18px',
        lg: '20px',
        xl: '25px',
        '2xl': '30px',
      },
      lineHeight: {
        xs: '21px',
        md: '21px',
        lg: '27px',
        xl: '34px',
        '2xl': '41px',
      },
    },
    lg: {
      fontWeight: 500,
      color: 'brand.500',
      fontFamily: 'body',
      fontSize: {
        xs: '16px',
        md: '20px',
        lg: '25px',
        xl: '30px',
        '2xl': '35px',
      },
      lineHeight: {
        xs: '22px',
        md: '24px',
        lg: '29px',
        xl: '36px',
        '2xl': '43px',
      },
    },
    '2xl': {
      fontWeight: 400,
      color: 'brand.500',
      fontFamily: 'body',
      fontSize: {
        xs: '14px',
        sm: '18px',
        md: '24px',
        lg: '32px',
        xl: '40px',
        '2xl': '48px',
      },
      lineHeight: {
        xs: '18px',
        sm: '22px',
        md: '29px',
        lg: '37px',
        xl: '46px',
        '2xl': '50px',
      },
    },
  },
};
