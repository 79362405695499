export const Heading = {
  variants: {
    h1: {
      fontWeight: 700,
      color: 'brand.500',
      fontFamily: 'serif',
      fontSize: {
        xs: '28px',
        sm: '30px',
        md: '40px',
        lg: '60px',
        xl: '80px',
        '2xl': '90px',
      },
      lineHeight: {
        xs: '35px',
        sm: '38px',
        md: '50px',
        lg: '85px',
        xl: '100px',
        '2xl': '113px',
      },
    },
    h2: {
      fontWeight: 700,
      color: 'brand.500',
      fontFamily: 'heading',
      fontSize: {
        xs: '28px',
        sm: '36px',
        md: '44px',
        lg: '52px',
        xl: '60px',
        '2xl': '70px',
      },
      lineHeight: {
        xs: '36px',
        sm: '42px',
        md: '54px',
        lg: '64px',
        xl: '72px',
        '2xl': '82px',
      },
    },
    h3: {
      fontWeight: 700,
      color: 'brand.500',
      fontFamily: 'body',
      fontSize: {
        xs: '30px',
        sm: '32px',
        md: '36px',
        lg: '44px',
        xl: '52px',
        '2xl': '60px',
      },
      lineHeight: {
        xs: '35px',
        sm: '37px',
        md: '44px',
        lg: '54px',
        xl: '62px',
        '2xl': '72px',
      },
    },
    h4: {
      fontWeight: 700,
      color: 'white',
      fontFamily: 'body',
      fontSize: {
        xs: '14px',
        sm: '18px',
        md: '24px',
        lg: '32px',
        xl: '40px',
        '2xl': '48px',
      },
      lineHeight: {
        xs: '21px',
        sm: '25px',
        md: '31px',
        lg: '39px',
        xl: '47px',
        '2xl': '55px',
      },
    },
    h6: {
      fontWeight: 700,
      color: 'brand.500',
      fontFamily: 'serif',
      fontSize: {
        xs: '16px',
        md: '20px',
        lg: '25px',
        xl: '30px',
        '2xl': '35px',
      },
      lineHeight: {
        xs: '22px',
        md: '24px',
        lg: '29px',
        xl: '36px',
        '2xl': '43px',
      },
    },
    h8: {
      fontWeight: 500,
      color: 'lightBlack',
      fontFamily: 'body',
      fontSize: {
        xs: '12px',
        md: '16px',
        lg: '20px',
        xl: '25px',
        '2xl': '25px',
      },
      lineHeight: {
        xs: '15px',
        md: '19px',
        lg: '24px',
        xl: '29px',
        '2xl': '30px',
      },
    },
  },
};
