import AppLayoutActionsTypes from './actionTypes';
import AppLayoutActions, { IAction } from './actionTypes';

export type IAppLayoutState = {
  modals: string[] | [];
  isDesktopView: boolean;
  navigationGoBack?: () => any;
  screeningType: 'brief' | 'survey' | '';
  currentScreeningStepId: `${number}-${number}`;
  screeningProgress: number;
  selectedTreatmentId: number | null;
};

export const defaultState: IAppLayoutState = {
  modals: [],
  isDesktopView: false,
  navigationGoBack: undefined,
  screeningType: '',
  currentScreeningStepId: '0-0',
  screeningProgress: 0,
  selectedTreatmentId: null,
};

export default (state: IAppLayoutState, action: IAction): IAppLayoutState => {
  switch (action.type) {
    case AppLayoutActionsTypes.ADD_MODAL: {
      return {
        ...state,
        modals: [...state.modals, action.payload],
      };
    }
    case AppLayoutActionsTypes.REMOVE_MODAL: {
      return {
        ...state,
        modals: state.modals.filter(m => m !== action.payload),
      };
    }
    case AppLayoutActionsTypes.REMOVE_ALL_MODALS: {
      return {
        ...state,
        modals: [],
      };
    }
    case AppLayoutActions.SET_APP_LAYOUT_VIEW_TYPE: {
      return {
        ...state,
        isDesktopView: action.payload,
      };
    }
    case AppLayoutActions.SET_NAVIGATION_GO_BACK: {
      return {
        ...state,
        navigationGoBack: action.payload,
      };
    }
    case AppLayoutActions.SET_SCREENING_TYPE: {
      return {
        ...state,
        screeningType: action.payload,
      };
    }
    case AppLayoutActions.CLEAR_SCREENING_TYPE: {
      return {
        ...state,
        screeningType: '',
      };
    }
    case AppLayoutActions.SET_CURRENT_SCREENING_STEP_ID: {
      return {
        ...state,
        currentScreeningStepId: action.payload,
      };
    }
    case AppLayoutActions.CLEAR_CURRENT_SCREENING_STEP_ID: {
      return {
        ...state,
        currentScreeningStepId: '0-0',
      };
    }
    case AppLayoutActions.SET_SCREENING_PROGRESS: {
      return {
        ...state,
        screeningProgress: action.payload,
      };
    }
    case AppLayoutActions.CLEAR_SCREENING_PROGRESS: {
      return {
        ...state,
        screeningProgress: 0,
      };
    }
    case AppLayoutActions.SET_SELECTED_TREATMENT_ID: {
      return {
        ...state,
        selectedTreatmentId: action.payload,
      };
    }
    case AppLayoutActions.CLEAR_SELECTED_TREATMENT_ID: {
      return {
        ...state,
        selectedTreatmentId: null,
      };
    }

    default: {
      return state;
    }
  }
};
