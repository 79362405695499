import { Action, ActionWithoutPayload } from '@interfaces/provider';
import { IParticipantResponse } from '@interfaces/participant';

enum ParticipantActions {
  GET_PARTICIPANT_DATA = 'GET_PARTICIPANT_DATA',
  CREATE_PARTICIPANT = 'CREATE_PARTICIPANT',
  CLEAR_PARTICIPANT_DATA = 'CLEAR_PARTICIPANT_DATA',
  SET_PARTICIPANT_DATA = 'SET_PARTICIPANT_DATA',
}

interface ICreateParticipantPayload {
  email?: string;
}

export interface ISetParticipantDataPayload {
  email: string;
  participantHash: string;
  currentStepId: string;
  expirationDate?: Date;
}

export interface IGetParticipantDataPayload {
  email?: string;
  sideEffect?: (response: IParticipantResponse) => void;
}

export type IGetParticipantData = Action<
  ParticipantActions.GET_PARTICIPANT_DATA,
  IGetParticipantDataPayload
>;

export type ISetParticipantData = Action<
  ParticipantActions.SET_PARTICIPANT_DATA,
  IParticipantResponse
>;

export type ICreateParticipant = Action<
  ParticipantActions.CREATE_PARTICIPANT,
  ICreateParticipantPayload
>;

export type IClearParticipantData =
  ActionWithoutPayload<ParticipantActions.CLEAR_PARTICIPANT_DATA>;

export type IAction =
  | IGetParticipantData
  | ISetParticipantData
  | ICreateParticipant
  | IClearParticipantData;

export default ParticipantActions;
