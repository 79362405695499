import React from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';

interface IProps {
  children: React.ReactNode;
  type?: 'button' | 'submit';
  styles?: object;
  variant: string;
  onClick?: () => any;
  disabled?: boolean;
}

const Button: React.FC<IProps> = ({
  children,
  styles,
  variant,
  onClick,
  disabled,
  type,
}: IProps) => {
  return (
    <ChakraButton
      type={type}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      {...{
        ...styles,
        ...(disabled && { cursor: 'default' }),
      }}
    >
      {children}
    </ChakraButton>
  );
};

export default Button;
