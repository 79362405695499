import ParticipantActionTypes, { IAction } from './actionTypes';
import { IParticipant } from '@interfaces/participant';
import { StateBuilder } from '@interfaces/stateBuilder';

export type IParticipantState = StateBuilder<IParticipant, null>;

export const defaultState: IParticipantState = {
  data: {} as IParticipant,
  meta: null,
  loading: false,
};

export default (
  state: IParticipantState,
  action: IAction,
): IParticipantState => {
  switch (action.type) {
    case ParticipantActionTypes.GET_PARTICIPANT_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ParticipantActionTypes.SET_PARTICIPANT_DATA: {
      const data = action.payload;
      return {
        ...state,
        data,
        loading: false,
      };
    }
    case ParticipantActionTypes.CREATE_PARTICIPANT: {
      return {
        ...state,
      };
    }
    case ParticipantActionTypes.CLEAR_PARTICIPANT_DATA: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
