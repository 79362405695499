export const Button = {
  variants: {
    'primary': {
      backgroundColor: 'brand.500',
      fontSize: 'sm',
      fontFamily: 'montserrat',
      fontWeight: 400,
      color: 'lightGray',
      borderRadius: '6px',
      _hover: {},
      _active: {},
      _disabled: {
        opacity: 0.5
      }
    },
    'bordered': {
      backgroundColor: 'lightGrey',
      color: 'brand.500',
      fontSize: 'sm',
      fontFamily: 'montserrat',
      fontWeight: 400,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'brand.500',
      borderRadius: '6px',
      _hover: {},
      _active: {},
      _disabled: {
        opacity: 0.5
      }
    },
    'secondary': {
      backgroundColor: 'brand.300',
      fontSize: {
        xs: '12px',
        md: '15px',
        lg: '20px',
        xl: '25px',
        '2xl': '30px'
      },
      fontFamily: 'body',
      fontWeight: 400,
      borderRadius: {
        xs: '15px',
        md: '40px'
      },
      color: 'white',
      _hover: {},
      _active: {},
      _disabled: {
        opacity: 0.5
      }
    }
  },
  defaultProps: {
    variant: 'primary'
  }
}
