export const Checkbox = {
  baseStyle: {
    icon: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'white',
      borderRadius: '50%',
      background: 'rgba(221, 221, 221, 0.38)',
    },
    control: {
      background: 'rgba(221, 221, 221, 0.38)',
      borderRadius: '50%',
      borderWidth: '4px',
      borderStyle: 'solid',
      borderColor: 'white',

      _checked: {
        background: 'brand.300',
      }
    },
    label: {
      fontFamily: 'body',
      color: 'descentGrey',
      fontSize: {
        xs: '16px',
        md: '20px',
        lg: '25px',
        xl: '30px',
        '2xl': '35px'
      },
      lineHeight: {
        xs: '22px',
        md: '24px',
        lg: '29px',
        xl: '36px',
        '2xl': '43px'
      }
    }
  },
  sizes: {
    sm: {
      control: {
        h: {
          xs: '16px',
          lg: '20px',
          xl: '24px'
        },
        w: {
          xs: '16px',
          lg: '20px',
          xl: '24px'
        }
      },
      icon: {
        h: {
          xs: '8px',
          lg: '10px',
          xl: '16px'
        },
        w: {
          xs: '8px',
          lg: '10px',
          xl: '16px'
        }
      }
    },
    md: {
      control: {
        h: {
          xs: '24px',
          lg: '28px',
          xl: '32px'
        },
        w: {
          xs: '24px',
          lg: '28px',
          xl: '32px'
        }
      },
      icon: {
        h: {
          xs: '16px',
          lg: '20px',
          xl: '24px'
        },
        w: {
          xs: '16px',
          lg: '20px',
          xl: '24px'
        }
      }
    },
    lg: {
      control: {
        h: {
          xs: '32px',
          lg: '36px',
          xl: '40px'
        },
        w: {
          xs: '32px',
          lg: '36px',
          xl: '40px'
        }
      },
      icon: {
        h: {
          xs: '24px',
          lg: '28px',
          xl: '32px'
        },
        w: {
          xs: '24px',
          lg: '28px',
          xl: '32px'
        }
      }
    },
  },
  defaultProps: {
    size: 'md'
  },
}
