import { Action, ActionWithoutPayload } from '@interfaces/provider';
import { ITreatmentResponse, ITreatment } from '@interfaces/treatment';

enum TreatmentsActions {
  GET_TREATMENT_DATA = 'GET_TREATMENT_DATA',
  SET_TREATMENT_DATA = 'SET_TREATMENT_DATA',
  CLEAR_TREATMENT_DATA = 'CLEAR_TREATMENT_DATA',
}

export type IGetTreatmentsData = Action<
  TreatmentsActions.GET_TREATMENT_DATA,
  ITreatment
>;

export type ISetTreatmentsData = Action<
  TreatmentsActions.SET_TREATMENT_DATA,
  ITreatmentResponse
>;

export type IClearTreatmentsData =
  ActionWithoutPayload<TreatmentsActions.CLEAR_TREATMENT_DATA>;

export type IAction =
  | IGetTreatmentsData
  | ISetTreatmentsData
  | IClearTreatmentsData;

export default TreatmentsActions;
