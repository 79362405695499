import createProvider from '@helpers/createProvider';
import reducer, { ISurveyState, defaultState } from './reducer';

export const {
  Provider: SurveyProvider,
  useState: useSurveyState,
  useDispatch: useSurveyDispatch,
  getDispatch: getSurveyDispatch,
  dispatch: surveyDispatchAction,
} = createProvider<ISurveyState>(reducer, defaultState);
