import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Link, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { navList } from '@core/app-layout/navList';
import Button from '@core/common-components/Button';
import { useAppLayoutState } from '@core/app-layout/provider';
import {
  lightHeaderRoutes,
  brandHeaderRoutes,
} from '@core/router/commonRoutes';
import useCurrentRouteKey from '@helpers/useCurrentRouteKey';

interface IProps {
  isDesktopView: boolean;
}

const MobileHeader: React.FC = () => {
  const routeKey = useCurrentRouteKey();
  const navigate = useNavigate();
  const { navigationGoBack: customGoBackFunc } = useAppLayoutState();
  const isLightTheme = lightHeaderRoutes.includes(routeKey);
  const isBrandTheme = brandHeaderRoutes.includes(routeKey);

  return (
    <Flex
      p="0px 12px 0px 28px"
      alignItems="center"
      justifyContent="space-between"
      bgColor={isLightTheme ? 'purpleWhite' : 'brand.500'}
      height="38px"
    >
      <ArrowBackIcon
        color={isLightTheme ? 'brand.300' : 'white'}
        w="20px"
        h="20px"
        cursor="pointer"
        onClick={() => (customGoBackFunc ? customGoBackFunc() : navigate(-1))}
      />
      <Image
        width="66px"
        src={isLightTheme ? '/NellieLogoH.svg' : '/NellieLogoWhite.svg'}
        cursor="pointer"
        onClick={() => navigate('/')}
      />
      {isLightTheme && <CloseIcon color="brand.300" w="14px" h="14px" />}
    </Flex>
  );
};

const DesktopHeader: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navListRender = navList.reduce(
    (acc, nav, i) =>
      acc.concat(
        <Link
          ml="40px"
          fontSize="sm"
          fontFamily="montserrat"
          href={nav.href}
          key={i}
          sx={{
            '@media (max-width: 1024px)': {
              ml: '20px',
            },
          }}
        >
          {t(nav.name)}
        </Link>,
      ),
    [] as React.ReactNode[],
  );

  return (
    <Flex
      p={{
        md: '0px 40px 0px 20px',
        '2xl': '0px 80px 0px 40px',
      }}
      alignItems="center"
      justifyContent="space-between"
      bgColor="headerMain"
      height="60px"
      sx={{
        '@media (max-width: 1024px)': {
          p: '0px 20px 0px 20px',
        },
      }}
    >
      <Box flexGrow="0">
        <Image
          width="90px"
          src="/NellieLogoH.svg"
          cursor="pointer"
          onClick={() => navigate('/')}
        />
      </Box>
      <Box flexGrow="3">{navListRender}</Box>
      <Box flexGrow="1" textAlign="right">
        <Button
          variant="bordered"
          styles={{ mr: '12px', width: '130px', height: '40px' }}
          onClick={() => navigate('/login')}
        >
          {t('header.log_in')}
        </Button>
        <Button
          variant="primary"
          styles={{ width: '130px', height: '40px' }}
          onClick={() => {}}
        >
          {t('header.get_started')}
        </Button>
      </Box>
    </Flex>
  );
};

const Header: React.FC<IProps> = ({ isDesktopView }: IProps) => {
  return isDesktopView ? <DesktopHeader /> : <MobileHeader />;
};

export default Header;
