import ScreeningResultsActionTypes, { IAction } from './actionTypes';
import { IScreeningResults } from '@interfaces/screeningResults';
import { StateBuilder } from '@interfaces/stateBuilder';

export type IScreeningResultsState = StateBuilder<IScreeningResults, null>;

export const defaultState: IScreeningResultsState = {
  data: {} as IScreeningResults,
  meta: null,
  loading: false,
};

export default (
  state: IScreeningResultsState,
  action: IAction,
): IScreeningResultsState => {
  switch (action.type) {
    case ScreeningResultsActionTypes.GET_SCREENING_RESULTS_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ScreeningResultsActionTypes.SET_SCREENING_RESULTS_DATA: {
      const data = action.payload;
      return {
        ...state,
        data,
        loading: false,
      };
    }
    case ScreeningResultsActionTypes.UPDATE_SCREENING_RESULTS_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ScreeningResultsActionTypes.CREATE_SCREENING_RESULTS_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ScreeningResultsActionTypes.CLEAR_SCREENING_RESULTS_DATA: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
