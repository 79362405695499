import React from 'react';
import { Box } from '@chakra-ui/react';

interface IProps {
  children: React.ReactNode;
  isDesktopView: boolean;
}

const Main: React.FC<IProps> = ({ children, isDesktopView }: IProps) => {
  return (
    <Box
      height={`calc(100% - ${isDesktopView ? '60px' : '38px'})`}
      minH={`calc(100vh - ${isDesktopView ? '60px' : '38px'})`}
    >
      {children}
    </Box>
  );
};

export default Main;
