import { Action, ActionWithoutPayload } from '@interfaces/provider';
import { IBriefResponse, IBrief } from '@interfaces/briefs';

enum BriefsActions {
  GET_BRIEFS_DATA = 'GET_BRIEFS_DATA',
  SET_BRIEFS_DATA = 'SET_BRIEFS_DATA',
  CLEAR_BRIEFS_DATA = 'CLEAR_BRIEFS_DATA',
  SET_BRIEFS_LOADING = 'SET_BRIEFS_LOADING',
}

export interface IGetBriefsDataPayload {
  id?: number | { $in: number[] };
  withSurvey?: boolean;
  withQuestions?: boolean;
  sort?: { priority: 'ASC' | 'DESC' };
}

export type IGetBriefsData = Action<
  BriefsActions.GET_BRIEFS_DATA,
  IGetBriefsDataPayload
>;

export type ISetBriefData = Action<
  BriefsActions.SET_BRIEFS_DATA,
  IBriefResponse
>;

export type ISetBriefsLoading = Action<
  BriefsActions.SET_BRIEFS_LOADING,
  boolean
>;

export type IClearBriefData =
  ActionWithoutPayload<BriefsActions.CLEAR_BRIEFS_DATA>;

export type IAction =
  | IGetBriefsData
  | ISetBriefData
  | ISetBriefsLoading
  | IClearBriefData;

export default BriefsActions;
