export default {
  breakpoints: {
    xs: '0px',
    sm: '480px',
    md: '768px',
    lg: '1346px',
    xl: '1680px',
    '2xl': '2080px',
  },
};
