import createProvider from '@helpers/createProvider';
import reducer, { IBriefsState, defaultState } from './reducer';

export const {
  Provider: BriefsProvider,
  useState: useBriefsState,
  useDispatch: useBriefsDispatch,
  getDispatch: getBriefsDispatch,
  dispatch: briefsDispatchAction,
} = createProvider<IBriefsState>(reducer, defaultState);
