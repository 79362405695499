export const Card = {
  variants: {
    result: {
      display: 'flex',
      flexDirection: 'column',
      bgColor: 'dirtyWhite',
      borderRadius: '12px',
      m: '12px',
      width: {
        xs: '230px',
        md: '230px',
        lg: '260px',
        xl: '318px',
        '2xl': '376px',
      },
      height: {
        xs: '160px',
        md: '160px',
        lg: '190px',
        xl: '220px',
        '2xl': '260px',
      },
      p: {
        xs: '15px',
        md: '18px',
        lg: '20px',
        xl: '28px',
      },
      CardHeader: {
        display: 'flex',
        alignItems: 'center',
        mb: {
          xs: '8px',
          md: '12px',
          lg: '15px',
          xl: '18px',
          '2xl': '20px',
        },
        '> div': {
          mr: {
            xs: '8px',
            '2xl': '22px',
          },
          bgColor: 'purpleGrey',
          borderRadius: '8px',
          textAlign: 'center',
          lineHeight: {
            xs: '28px',
            lg: '38px',
            '2xl': '57px',
          },
          width: {
            xs: '28px',
            lg: '38px',
            '2xl': '57px',
          },
          minWidth: {
            xs: '28px',
            lg: '38px',
            '2xl': '57px',
          },
          height: {
            xs: '28px',
            lg: '38px',
            '2xl': '57px',
          },
        },
      },
      CardBody: {
        mb: {
          md: '16px',
          lg: '18px',
          xl: '26px',
          '2xl': '36px',
        },
        'sub div': {
          width: {
            xs: '14px',
            md: '14px',
            lg: '16px',
            xl: '22px',
          },
          height: {
            xs: '14px',
            md: '14px',
            lg: '16px',
            xl: '22px',
          },
          lineHeight: {
            xs: '14px',
            md: '14px',
            lg: '16px',
            xl: '22px',
          },
          fontSize: {
            xs: '12px',
            md: '12px',
            lg: '14px',
            xl: '18px',
          },
        },
      },
      CardFooter: {},
    },
    treatment: {
      position: 'relative',
      display: 'inline-block',
      p: '28px 20px',
      borderRadius: '8px',
      minW: '260px',
      maxW: '260px',
      minH: '340px',
      overflowX: 'hidden',
      mr: '25px',
      cursor: 'pointer',
      _before: {
        content: '""',
        position: 'absolute',
        width: '342px',
        height: '342px',
        borderRadius: '50%',
        left: '64px',
        top: '-184px',
        background: '#FFFFFF',
        opacity: '0.04',
      },
      CardHeader: {
        h2: {
          color: 'white',
        },
        mb: '9px',
      },
      CardBody: {
        mb: '30px',
        '> div': {
          '> div:first-child': {
            mb: '15px',
          },
        },
        svg: {
          width: '21px',
          height: '21px',
          path: {
            fill: 'white',
          },
        },
        p: {
          color: 'white',
          fontSize: '18px',
          ml: '5px',
        },
      },
      CardFooter: {
        button: {
          w: '160px',
          h: '33px',
          bgColor: 'lightBlue',
          color: 'brand.500',
          borderRadius: '24px',
          fontWeight: '700',
          textTransform: 'uppercase',
        },
      },
    },
    radioCard: {
      mb: '40px !important',
      fontSize: {
        xs: '16px',
        md: '20px',
        lg: '25px',
        xl: '30px',
        '2xl': '35px',
      },
      fontWeight: 700,
      color: 'brand.500',
      CardBody: {
        cursor: 'pointer',
        textAlign: 'center',
        display: 'table-cell',
        verticalAlign: 'middle',
        px: '15px',
        py: { xs: '30px', md: '35px', lg: '40px', xl: '50px', '2xl': '65px' },
        boxShadow: '1px 1px 40px rgba(0, 0, 0, 0.08)',
        backdropFilter: 'blur(6.5px)',
        _checked: {
          bg: 'brand.500',
          color: 'white',
        },
        borderRadius: {
          xs: '20px',
          md: '30px',
          lg: '40px',
          xl: '50px',
          '2xl': '60px',
        },
        w: {
          xs: '134px',
          md: '160px',
          lg: '180px',
          xl: '206px',
          '2xl': '264px',
        },
        h: {
          xs: '134px',
          md: '160px',
          lg: '180px',
          xl: '206px',
          '2xl': '264px',
        },
      },
    },
  },
};
