export default {
  fonts: {
    //heading: 'Merriweather',
    //body: 'Merriweather',
    heading: 'Raleway',
    body: 'Raleway',
    serif: 'Merriweather',
    montserrat: 'Montserrat'
  },
  fontSizes: {
    sm: '15px',
  },
  lineHeights: {

  },
  letterSpacings: {

  }
}
