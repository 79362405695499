import ParticipantActionTypes, {
  IAction,
  IGetParticipantDataPayload,
} from './actionTypes';
import { getParticipantDispatch } from './';
import { generateB64Hash } from '@helpers/generateB64Hash';
import { IParticipantResponse } from '@interfaces/participant';
import services from '@helpers/services';

const dispatchAction = (action: IAction) => {
  const dispatch = getParticipantDispatch();
  return dispatch(action);
};

const participantActions = {
  setParticipantData: (payload: IParticipantResponse) =>
    dispatchAction({
      type: ParticipantActionTypes.SET_PARTICIPANT_DATA,
      payload,
    }),
  clearParticipantData: () =>
    dispatchAction({
      type: ParticipantActionTypes.CLEAR_PARTICIPANT_DATA,
    }),
};

const getParticipantData = (payload: IGetParticipantDataPayload) => {
  const email = payload?.email;
  const date = new Date();
  date.setDate(date.getDate() + 30);
  const expirationDate = date.toISOString();
  if (email) {
    services.http
      .post('/participants/get', {
        email,
      })
      .then((response: { data: IParticipantResponse }) => {
        if (payload?.sideEffect) {
          payload?.sideEffect(response.data);
        }
        services.cookie.set('email', email, {
          expires: new Date(expirationDate),
        });
        services.cookie.remove('participantHash');
        participantActions.setParticipantData(response.data);
      });
  }
  if (!email) {
    const participantHash = services.cookie.get('participantHash');
    services.http
      .post('/participants/get', {
        participantHash,
      })
      .then((response: { data: IParticipantResponse }) => {
        services.cookie.set('participantHash', participantHash, {
          expires: new Date(expirationDate),
        });
        services.cookie.remove('email');
        participantActions.setParticipantData(response.data);
      });
  }
};

const createParticipant = (
  email?: string | null,
  options?: { sideEffect: () => void },
) => {
  services.cookie.remove('email');
  services.cookie.remove('participantHash');
  const date = new Date();
  date.setDate(date.getDate() + 30);
  const expirationDate = date.toISOString();
  if (email) {
    services.http
      .post('/participants/create', {
        email,
      })
      .then((response: { data: IParticipantResponse }) => {
        participantActions.setParticipantData(response.data);
        services.cookie.set('email', email, {
          expires: new Date(expirationDate),
        });
        if (response.data.id) {
          options?.sideEffect();
        }
      });
  }
  if (!email) {
    const participantHash =
      services.cookie.get('participantHash') || generateB64Hash();
    services.http
      .post('/participants/create', {
        participantHash,
        expirationDate: expirationDate,
      })
      .then((response: { data: IParticipantResponse }) => {
        participantActions.setParticipantData(response.data);
        services.cookie.set('participantHash', participantHash, {
          expires: new Date(expirationDate),
        });
        if (response.data.id) {
          options?.sideEffect();
        }
      });
  }
};

export default {
  ...participantActions,
  getParticipantData,
  createParticipant,
};
