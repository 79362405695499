import React from 'react';
import reduce from 'lodash/reduce';
import { commonRoutes } from '@core/router/commonRoutes';
import { Route as RouteData } from '@interfaces/router';
import routes from '@core/router';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

const Routes: React.FC = () => {
  return (
    <ReactRoutes>
      {reduce(
        commonRoutes,
        (acc: JSX.Element[], e: string) => {
          const route: RouteData = routes[e];
          if (route !== undefined) {
            return acc.concat(
              <Route
                key={e}
                path={route.path}
                element={route.component}
                caseSensitive={route.caseSensitive}
              />,
            );
          }
          return acc;
        },
        [],
      )}
    </ReactRoutes>
  );
};

export default Routes;
