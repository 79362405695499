import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import fonts from './fonts';
import breakpoints from './breakpoints';
import * as components from './components'


const theme = extendTheme({
  ...colors,
  ...fonts,
  ...breakpoints,
  components,
});

export default theme;
