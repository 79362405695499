import React from 'react';
import { IDispatch, IReducer } from '@interfaces/provider';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types
export default <StateType extends {}>(
  reducer: IReducer<StateType>,
  defaultState: StateType,
  displayName?: string,
) => {
  const StateContext = React.createContext(defaultState);
  const DispatchContext = React.createContext((action: any) => {
    console.log(action);
  });

  if (displayName) StateContext.displayName = displayName;

  let dispatchInstance: IDispatch;
  let stateInstance: StateType;

  const useState = () => React.useContext(StateContext);
  const useDispatch = () => React.useContext(DispatchContext);
  const getDispatch = () => dispatchInstance;
  const getState = () => stateInstance;

  const Provider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);

    dispatchInstance = dispatch;
    stateInstance = state;

    return (
      <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
        </DispatchContext.Provider>
        </StateContext.Provider>
    );
  };

  return {
    useState,
    useDispatch,
    Provider,
    getDispatch,
    dispatch: ((...args) => dispatchInstance(...args)) as IDispatch,
    getState,
  };
};
