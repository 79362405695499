export const commonRoutes: string[] = [
  'Introduction',
  'Screening',
  'Results',
  'TreatmentDetails',
];

export const lightHeaderRoutes: string[] = ['Introduction', 'Screening'];

export const brandHeaderRoutes: string[] = ['Results', 'TreatmentDetails'];
