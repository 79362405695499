import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import i18n from '@core/translation';
import theme from '@core/theming';
import { AppLayoutProvider } from '@core/app-layout/provider';
import { ParticipantProvider } from '@features/participants/providers';
import { ScreeningQuestionsProvider } from '@features/screening/providers/screeningQuestions';
import { ScreeningResultsProvider } from '@features/screening/providers/screeningResults';
import { SurveyProvider } from '@features/screening/providers/surveys';
import { BriefsProvider } from '@features/screening/providers/briefs';
import { ResultsProvider } from '@features/results/providers';
import { TreatmentsProvider } from '@features/treatment/provider';
import Cookie from '@helpers/cookie';
import { IServices } from '@interfaces/services';
import axios from '@core/http/axios';
import { setServices, ServicesContext } from '@helpers/services';

interface IProps {
  children: React.ReactNode;
}

const cookie = new Cookie();

let services: IServices = {
  cookie,
  http: axios,
};

setServices(services);

const MainProvider: React.FC<IProps> = ({ children }: IProps) => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <ServicesContext.Provider value={services}>
            <AppLayoutProvider>
              <ParticipantProvider>
                <ScreeningQuestionsProvider>
                  <ScreeningResultsProvider>
                    <SurveyProvider>
                      <BriefsProvider>
                        <ResultsProvider>
                          <TreatmentsProvider>{children}</TreatmentsProvider>
                        </ResultsProvider>
                      </BriefsProvider>
                    </SurveyProvider>
                  </ScreeningResultsProvider>
                </ScreeningQuestionsProvider>
              </ParticipantProvider>
            </AppLayoutProvider>
          </ServicesContext.Provider>
        </I18nextProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default MainProvider;
