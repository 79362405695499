import SurveysActionTypes, { IAction } from './actionTypes';
import { ISurvey } from '@interfaces/surveys';
import { StateBuilder } from '@interfaces/stateBuilder';
import omit from 'lodash/omit';

export type ISurveyState = StateBuilder<Record<`${number}`, ISurvey>, null>;

export const defaultState: ISurveyState = {
  data: {} as Record<`${number}`, ISurvey>,
  meta: null,
  loading: false,
};

export default (state: ISurveyState, action: IAction): ISurveyState => {
  switch (action.type) {
    case SurveysActionTypes.GET_SURVEY_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SurveysActionTypes.SET_SURVEY_DATA: {
      const data = action.payload;
      return {
        ...state,
        data,
        loading: false,
      };
    }
    case SurveysActionTypes.CLEAR_SURVEY_DATA: {
      return defaultState;
    }
    case SurveysActionTypes.CLEAR_SURVEY_DATA_BY_IDS: {
      return {
        ...state,
        data: omit(state.data, action.payload) as Record<`${number}`, ISurvey>,
      };
    }
    default: {
      return state;
    }
  }
};
