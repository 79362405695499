export default {
  colors: {
    brand: {
      500: '#3A38A6',
      300: '#8393C9',
    },
    teal: {
      500: '#007A7C',
    },
    lightGray: '#EAEAF0',
    descentGrey: '#8C8A99',
    headerMain: 'rgba(255, 255, 255, 0.9)',
    purpleWhite: '#F6F8FD',
    white: '#FFFFFF',
    lightBlue: '#CED7F3',
    paleGrey: '#979797',
    purpleGrey: '#E0DFEA',
    dirtyWhite: '#F2F2F2',
    moderateGrey: '#A5A5AD',
    lightBlack: '#28282D',
    progressGrey: '#DDDDDD',
    treatmentHeader: '#2E2E2E',
    grey: '#808080',
    lightBlack02: '#333333',
    purpleGrey02: '#F4F7FF',
    lightGrey02: '#3C3C3C',
    dirtyWhite02: '#F8F8F8',
    darkNavy: '#070A39',
  },
};
