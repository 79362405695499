import React from 'react';
import assign from 'lodash/assign';
import { IServices } from '@interfaces/services';

export const ServicesContext = React.createContext<IServices>({} as IServices);

export const useServices = () => React.useContext(ServicesContext);

const serviecsInstance: IServices = {} as IServices;

export const setServices = (services: IServices): void => {
  assign(serviecsInstance, services);
};

export default serviecsInstance;
