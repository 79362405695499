import BriefsActionTypes, { IAction } from './actionTypes';
import { IBrief } from '@interfaces/briefs';
import { StateBuilder } from '@interfaces/stateBuilder';

export type IBriefsState = StateBuilder<Record<`${number}`, IBrief>, null>;

export const defaultState: IBriefsState = {
  data: {} as Record<`${number}`, IBrief>,
  meta: null,
  loading: false,
};

export default (state: IBriefsState, action: IAction): IBriefsState => {
  switch (action.type) {
    case BriefsActionTypes.GET_BRIEFS_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case BriefsActionTypes.SET_BRIEFS_DATA: {
      const data = action.payload;
      return {
        ...state,
        data,
        loading: false,
      };
    }
    case BriefsActionTypes.SET_BRIEFS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case BriefsActionTypes.CLEAR_BRIEFS_DATA: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
