const generateString = (length: number): string => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

export const generateB64Hash = (): string => {
  const timestamp = Date.now()
  const randomString = generateString(7)

  return `${timestamp}-${window.btoa(randomString)}`
}
