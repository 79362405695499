import createProvider from '@helpers/createProvider';
import reducer, { ITreatmentsState, defaultState } from './reducer';

export const {
  Provider: TreatmentsProvider,
  useState: useTreatmentsState,
  useDispatch: useTreatmentsDispatch,
  getDispatch: getTreatmentsDispatch,
  dispatch: treatmentsDispatchAction,
} = createProvider<ITreatmentsState>(reducer, defaultState);
