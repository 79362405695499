import ResultsActionTypes, { IAction } from './actionTypes';
import { ITreatment } from '@interfaces/treatment';
import { StateBuilder } from '@interfaces/stateBuilder';

export type ITreatmentsState = StateBuilder<ITreatment[] | ITreatment, null>;

export const defaultState: ITreatmentsState = {
  data: {} as ITreatment,
  meta: null,
  loading: false,
};

export default (state: ITreatmentsState, action: IAction): ITreatmentsState => {
  switch (action.type) {
    case ResultsActionTypes.GET_TREATMENT_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ResultsActionTypes.SET_TREATMENT_DATA: {
      const data = action.payload;
      return {
        ...state,
        data,
        loading: false,
      };
    }
    case ResultsActionTypes.CLEAR_TREATMENT_DATA: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
