import { matchRoutes, useLocation } from 'react-router-dom';
import values from 'lodash/values';
import entries from 'lodash/entries';
import routes from '@core/router';

const useCurrentPathKey = () => {
  const location = useLocation();
  const route = matchRoutes(values(routes), location);

  const routeKey = entries(routes).reduce((acc, e) => {
    const [key, module] = e;
    if (module.path === route?.[0]?.route.path) {
      acc = key;
      return acc;
    }
    return acc;
  }, '');

  return routeKey;
};

export default useCurrentPathKey;
