import ResultsActionTypes, { IAction } from './actionTypes';
import { IResult } from '@interfaces/result';
import { StateBuilder } from '@interfaces/stateBuilder';

export type IResultsState = StateBuilder<IResult[], null>;

export const defaultState: IResultsState = {
  data: [] as IResult[],
  meta: null,
  loading: false,
};

export default (state: IResultsState, action: IAction): IResultsState => {
  switch (action.type) {
    case ResultsActionTypes.GET_RESULT_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ResultsActionTypes.SET_RESULT_DATA: {
      const data = action.payload;
      return {
        ...state,
        data,
        loading: false,
      };
    }
    case ResultsActionTypes.CLEAR_RESULT_DATA: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
