import React from 'react';
import loadable from '@loadable/component';
import { Module } from '@interfaces/router';
import LoadingPage from '@core/common-components/LoadingPage';

const modules: Module[] = [];

const LoadableIntroduction = loadable(
  () => import('@features/app/pages/IntroductionPage'),
  {
    fallback: <LoadingPage />,
  },
);

const LoadableScreening = loadable(
  () => import('@features/app/pages/ScreeningPage'),
  {
    fallback: <LoadingPage />,
  },
);

const LoadableResults = loadable(
  () => import('@features/app/pages/ResultsPage'),
  {
    fallback: <LoadingPage />,
  },
);

const LoadableTreatmentDetails = loadable(
  () => import('@features/app/pages/TreatmentDetailsPage'),
  {
    fallback: <LoadingPage />,
  },
);

const introductionModule = {
  routes: {
    Introduction: {
      path: '/',
      component: <LoadableIntroduction />,
      caseSensitive: true,
    },
  },
};

const screeningModule = {
  routes: {
    Screening: {
      path: '/screening',
      component: <LoadableScreening />,
      caseSensitive: true,
    },
  },
};

const reslutsModule = {
  routes: {
    Results: {
      path: '/results',
      component: <LoadableResults />,
      caseSensitive: true,
    },
  },
};

const treatmentDetailsModule = {
  routes: {
    TreatmentDetails: {
      path: '/treatments/:id',
      component: <LoadableTreatmentDetails />,
      caseSensitive: true,
    },
  },
};

modules.push(introductionModule);
modules.push(screeningModule);
modules.push(reslutsModule);
modules.push(treatmentDetailsModule);

export default modules;
