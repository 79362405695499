import { Action, ActionWithoutPayload } from '@interfaces/provider';
import {
  IScreeningResults,
  IScreeningResultsResponse,
} from '@interfaces/screeningResults';

enum ParticipantActions {
  GET_SCREENING_RESULTS_DATA = 'GET_SCREENING_RESULTS_DATA',
  SET_SCREENING_RESULTS_DATA = 'SET_SCREENING_RESULTS_DATA',
  UPDATE_SCREENING_RESULTS_DATA = 'UPDATE_SCREENING_RESULTS_DATA',
  CREATE_SCREENING_RESULTS_DATA = 'CREATE_SCREENING_RESULTS_DATA',
  CLEAR_SCREENING_RESULTS_DATA = 'CLEAR_SCREENING_RESULTS_DATA',
}

export interface IGetScreeningResultsDataPayload {
  id?: number;
  participantId?: number;
}

export interface ICreateScreeningResultsDataPayload extends IScreeningResults {
  participantId: number;
}

export interface IUpdateScreeningResultsDataPayload extends IScreeningResults {
  participantId: number;
  id: number;
  increment?: string;
}

export type IGetScreeningResultsData = Action<
  ParticipantActions.GET_SCREENING_RESULTS_DATA,
  IGetScreeningResultsDataPayload
>;

export type ISetScreeningResultsData = Action<
  ParticipantActions.SET_SCREENING_RESULTS_DATA,
  IScreeningResultsResponse
>;

export type IUpdateScreeningResultsData = Action<
  ParticipantActions.UPDATE_SCREENING_RESULTS_DATA,
  IUpdateScreeningResultsDataPayload
>;

export type ICreateScreeningResultsData = Action<
  ParticipantActions.CREATE_SCREENING_RESULTS_DATA,
  ICreateScreeningResultsDataPayload
>;

export type IClearScreeningResultsData =
  ActionWithoutPayload<ParticipantActions.CLEAR_SCREENING_RESULTS_DATA>;

export type IAction =
  | IGetScreeningResultsData
  | ISetScreeningResultsData
  | IUpdateScreeningResultsData
  | ICreateScreeningResultsData
  | IClearScreeningResultsData;

export default ParticipantActions;
