import React, { useEffect } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import Header from '@core/app-layout/Header';
import Main from '@core/app-layout/Main';
import Routes from '@core/app-layout/Routes';
import participantActions from '@features/participants/providers/actions';
import services from '@helpers/services';

const AppLayout = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const { getParticipantData } = participantActions;
    const participantEmail = services.cookie.get('email');
    const participantHash = services.cookie.get('participantHash');
    if (participantEmail || participantHash) {
      getParticipantData({ email: participantEmail });
    }
  }, []);

  return (
    <Box bgColor="purpleWhite">
      <Header isDesktopView={!isMobile} />
      <Main isDesktopView={!isMobile}>
        <Routes />
      </Main>
    </Box>
  );
};

export default AppLayout;
