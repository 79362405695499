import { Action, ActionWithoutPayload } from '@interfaces/provider';

enum AppLayoutActions {
  ADD_MODAL = 'ADD_MODAL',
  REMOVE_MODAL = 'REMOVE_MODAL',
  REMOVE_ALL_MODALS = 'REMOVE_ALL_MODALS',
  SET_APP_LAYOUT_VIEW_TYPE = 'SET_APP_LAYOUT_VIEW_TYPE',
  SET_NAVIGATION_GO_BACK = 'SET_NAVIGATION_GO_BACK',
  CLEAR_NAVIGATION_GO_BACK = 'CLEAR_NAVIGATION_GO_BACK',
  SET_SCREENING_TYPE = 'SET_SCREENING_TYPE',
  CLEAR_SCREENING_TYPE = 'CLEAR_SCREENING_TYPE',
  SET_CURRENT_SCREENING_STEP_ID = 'SET_CURRENT_SCREENING_STEP_ID',
  CLEAR_CURRENT_SCREENING_STEP_ID = 'CLEAR_CURRENT_SCREENING_STEP_ID',
  SET_SCREENING_PROGRESS = 'SET_SCREENING_PROGRESS',
  CLEAR_SCREENING_PROGRESS = 'CLEAR_SCREENING_PROGRESS',
  SET_SELECTED_TREATMENT_ID = 'SET_SELECTED_TREATMENT_ID',
  CLEAR_SELECTED_TREATMENT_ID = 'CLEAR_SELECTED_TREATMENT_ID',
}

export type IAddModal = Action<AppLayoutActions.ADD_MODAL, string>;

export type IRemoveModal = Action<AppLayoutActions.REMOVE_MODAL, string>;

export type IRemoveAllModals =
  ActionWithoutPayload<AppLayoutActions.REMOVE_ALL_MODALS>;

export type ISetAppLayoutViewType = Action<
  AppLayoutActions.SET_APP_LAYOUT_VIEW_TYPE,
  boolean
>;

export type ISetNavigationGoBack = Action<
  AppLayoutActions.SET_NAVIGATION_GO_BACK,
  () => any
>;

export type IClearNavigationGoBack =
  ActionWithoutPayload<AppLayoutActions.CLEAR_NAVIGATION_GO_BACK>;

export type ISetScreeningType = Action<
  AppLayoutActions.SET_SCREENING_TYPE,
  'survey' | 'brief'
>;

export type IClearScreeningType =
  ActionWithoutPayload<AppLayoutActions.CLEAR_SCREENING_TYPE>;

export type ISetCurrentScreeningStepId = Action<
  AppLayoutActions.SET_CURRENT_SCREENING_STEP_ID,
  `${number}-${number}`
>;

export type IClearCurrentScreeningStepId =
  ActionWithoutPayload<AppLayoutActions.CLEAR_CURRENT_SCREENING_STEP_ID>;

export type ISetScreeningProgress = Action<
  AppLayoutActions.SET_SCREENING_PROGRESS,
  number
>;

export type IClearScreeningProgress =
  ActionWithoutPayload<AppLayoutActions.CLEAR_SCREENING_PROGRESS>;

export type ISetSelectedTreatmentId = Action<
  AppLayoutActions.SET_SELECTED_TREATMENT_ID,
  number
>;

export type IClearSelectedTreatmentId =
  ActionWithoutPayload<AppLayoutActions.CLEAR_SELECTED_TREATMENT_ID>;

export type IAction =
  | IAddModal
  | IRemoveModal
  | IRemoveAllModals
  | ISetAppLayoutViewType
  | ISetNavigationGoBack
  | IClearNavigationGoBack
  | ISetScreeningType
  | IClearScreeningType
  | ISetCurrentScreeningStepId
  | IClearCurrentScreeningStepId
  | ISetScreeningProgress
  | IClearScreeningProgress
  | ISetSelectedTreatmentId
  | IClearSelectedTreatmentId;

export default AppLayoutActions;
