import assign from 'lodash/assign';
import reduce from 'lodash/reduce';
import { Route as RouteData } from '@interfaces/router';
import modules from './Modules';

const routes: Record<string, RouteData> = {
  ...reduce(modules, (acc, e) => assign(acc, e.routes), {}),
};

export default routes;
