import { Action, ActionWithoutPayload } from '@interfaces/provider';
import { ISurveyResponse } from '@interfaces/surveys';

enum SurveysActions {
  GET_SURVEY_DATA = 'GET_SURVEY_DATA',
  SET_SURVEY_DATA = 'SET_SURVEY_DATA',
  CLEAR_SURVEY_DATA = 'CLEAR_SURVEY_DATA',
  CLEAR_SURVEY_DATA_BY_IDS = 'CLEAR_SURVEY_DATA_BY_IDS',
}

export type IGetSurveyData = Action<
  SurveysActions.GET_SURVEY_DATA,
  { id?: number }
>;

export type ISetSurveyData = Action<
  SurveysActions.SET_SURVEY_DATA,
  ISurveyResponse
>;

export type IClearSurveyData =
  ActionWithoutPayload<SurveysActions.CLEAR_SURVEY_DATA>;

export type IClearSurveyDataByIds = Action<
  SurveysActions.CLEAR_SURVEY_DATA_BY_IDS,
  Array<string>
>;

export type IAction =
  | IGetSurveyData
  | ISetSurveyData
  | IClearSurveyData
  | IClearSurveyDataByIds;

export default SurveysActions;
